import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import axios from "axios";
import PublishIcon from "@mui/icons-material/Publish";
import { API_URL, loadMessages } from "../actions/auth";
import InputBase from "@mui/material/InputBase";
import CircularProgress from "@mui/material/CircularProgress";
import { alertMessage, loadUser } from "../actions/auth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Messenger() {
  const state = useSelector((state) => state);
  const [isSending, setIsSending] = useState("");

  const [open2, setOpen2] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);

  const [loading, setLoading] = React.useState(true);

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedContact, setSelectedContact] = useState("");

  const [selectedContactIndex, setSelectedContactIndex] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [chatId, setChatId] = useState("");
  const [messageIndex, setMessageIndex] = useState("");
  const [messageKey, setMessageKey] = useState("");

  const [chatMessages, setChatMessages] = useState([]);
  const [selectedContactName, setSelectedContactName] = useState("");
  const [audio, setAudio] = useState(
    new Audio(require("../sounds/message.mp3"))
  );
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      audio.load();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function playAudio() {
    const audioPromise = audio.play();
    if (audioPromise !== undefined) {
      audioPromise
        .then((_) => {
          // autoplay started
          console.log(audioPromise);
        })
        .catch((err) => {
          console.log("___");
          console.log(err);
          // catch dom exception
          console.info(err);
        });
    }
  }

  function getSize(fileSize) {
    if (fileSize < 1024) {
      return <p>Size: {(fileSize / 1024).toFixed(2)} B</p>;
    } else if (fileSize > 1024 && fileSize < 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} KB</p>;
    } else if (fileSize > 1024 * 1024 && fileSize < 25 * 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB</p>;
    } else if (fileSize > 25 * 1024 * 1024) {
      handleClose();
      createAlert(
        "ERROR",
        "File too big.",
        "File size should be less than 25 MB."
      );
    }
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  handleClose();
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function onFileUpload() {
    setIsSending(true);
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append("file", selectedFile, selectedFile.name);

    // Details of the uploaded file
    console.log(selectedFile);

    // Request made to the backend api
    // Send formData object
    var time = new Date();
    state.auth.client.send(
      JSON.stringify({
        type: "text",
        sender: state.auth.user.email,
        receiver: selectedContact,
        message: textMessage,
        group_name: state.auth.user.email.replace("@", "").replace(".", ""),
        time: time.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }),
        date:
          time.getDate() +
          " " +
          monthNames[time.getMonth()] +
          ", " +
          time.getFullYear(),
      })
    );
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };
    var time = new Date();
    formData.append("sender", state.auth.user.email);
    formData.append("receiver", selectedContact);
    formData.append("message", selectedFile.name);
    formData.append(
      "time",
      time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
    );
    formData.append("institution_id", state.auth.user.institution_id);
    formData.append("sent_by", "principal");
    formData.append(
      "date",
      time.getDate() +
        " " +
        monthNames[time.getMonth()] +
        ", " +
        time.getFullYear()
    );

    formData.append("type", "attachment");
    formData.append(
      "sender_name",
      state.auth.user.firstname + " " + state.auth.user.lastname
    );
    formData.append("receiver_name", selectedContactName);

    axios.post(API_URL + "/send_attachment", formData, config);

    handleClose();
    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);
    handleOpen();
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  function handleContactClick(contact, index, name) {
    setSelectedContact(contact);
    setSelectedContactIndex(index);
    setSelectedContactName(name);

    if (
      chatMessages[index][contact][state.auth.user.email] &&
      chatMessages[index][contact][state.auth.user.email] != 0
    ) {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["sender"] = selectedContact;
      body["receiver"] = state.auth.user.email;
      body = JSON.stringify(body);

      axios.post(API_URL + "/mark_read", body, config);
    }

    var tempMessages = chatMessages;
    tempMessages[index][contact][state.auth.user.email] = 0;
    setChatMessages(tempMessages);
  }

  function handleTextChange(e) {
    setTextMessage(e.target.value);
  }

  const params = new URLSearchParams(window.location.search);
  var contactList;
  var chats;
  var messages;

  function sendMessage() {
    setIsSending(true);

    if (textMessage != "") {
      var time = new Date();

      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      var time = new Date();
      body["sender"] = state.auth.user.email;
      body["receiver"] = selectedContact;
      body["message"] = textMessage;
      body["time"] = time.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      body["institution_id"] = state.auth.user.institution_id;
      body["sent_by"] = "principal";
      body["sender_name"] =
        state.auth.user.firstname + " " + state.auth.user.lastname;
      body["receiver_name"] = selectedContactName;
      body["date"] =
        time.getDate() +
        " " +
        monthNames[time.getMonth()] +
        ", " +
        time.getFullYear();

      body["type"] = "text";
      body = JSON.stringify(body);

      axios.post(API_URL + "/send_message", body, config);
    }
  }

  function deleteMessage() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["chat_id"] = chatId;
    body["message_key"] = messageIndex;

    var msgKey = messageKey;
    for (var i = 0; i < chatMessages.length; i++) {
      for (var j = 0; j < Object.keys(chatMessages[i]).length; j++) {
        if (Object.keys(chatMessages[i])[j].includes("@")) {
          msgKey = Object.keys(chatMessages[i])[j];
          break;
        }
      }

      var a = chatMessages;
      if (chatMessages[i]["chat_id"] == chatId) {
        try {
          delete chatMessages[i][messageKey]["messages"][messageIndex];
        } catch (error) {
          delete chatMessages[i][msgKey]["messages"][messageIndex];
        }
        setChatMessages(chatMessages);
        setTextMessage("a");
        setTextMessage("");
        break;
      }
    }
    body = JSON.stringify(body);

    axios.post(API_URL + "/delete_message", body, config);
    setOpen2(false);
  }

  function getMessage(key, message, align, index, chat_id) {
    if (message != null && Object.keys(message).length != 0) {
      if (message["type"] == "attachment") {
        return (
          <span>
            <span
              style={{
                borderBottomRightRadius: align ? 2 : 10,
                borderBottomLeftRadius: align ? 10 : 2,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: 10,
                backgroundColor: align ? "#2171b5" : "#31a354",
                alignSelf: "flex-start",
                display: "inline-block",
              }}
            >
              <a
                target="_blank"
                style={{ color: "white" }}
                href={message[key].split("#link")[1]}
              >
                {message[key].split("#link")[0]}
              </a>
              <span
                style={{
                  margin: "15px 0px 0px 20px",
                  fontSize: "10px",
                  color: "white",
                }}
              >
                {message["time"]}
                {align ? (
                  <KeyboardArrowDownIcon
                    onClick={(event) => {
                      setChatId(chat_id);
                      setMessageIndex(index);
                      setMessageKey(key);
                      setAnchorEl(event.currentTarget);
                    }}
                    style={{
                      color: "white",
                    }}
                  />
                ) : (
                  ""
                )}
              </span>
            </span>
          </span>
        );
      } else {
        return (
          <span>
            <span
              style={{
                borderBottomRightRadius: align ? 2 : 10,
                borderBottomLeftRadius: align ? 10 : 2,
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                padding: 10,
                backgroundColor: align ? "#2171b5" : "#31a354",
                alignSelf: "flex-start",
                display: "inline-block",
                color: "white",
              }}
            >
              {message[key]}
              <span
                style={{
                  margin: "15px 0px 0px 20px",
                  fontSize: "10px",
                }}
              >
                {message["time"]}
                {align ? (
                  <KeyboardArrowDownIcon
                    onClick={(event) => {
                      setChatId(chat_id);
                      setMessageIndex(index);
                      setMessageKey(key);
                      setAnchorEl(event.currentTarget);
                    }}
                    style={{
                      marginTop: "-10px",
                      marginRight: "-5px",
                      color: "white",
                    }}
                  />
                ) : (
                  ""
                )}
              </span>
            </span>
          </span>
        );
      }
    } else {
      return (
        <span
          style={{
            borderRadius: "0px",
            margin: "-40px 0px 0px 10px",
            display: "inline-block",
            backgroundColor: "white",
            color: "#2171b5",
          }}
        >
          <h3>Send your first message!</h3>
        </span>
      );
    }
  }
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  function getMessages() {
    if (state.auth.messages && selectedContact) {
      var newMessages;
      messages =
        chatMessages[selectedContactIndex][selectedContact]["messages"];

      newMessages = Object.keys(messages).map((key, index) => (
        <ListItemText
          style={{ margin: "10px" }}
          align={
            Object.keys(messages[key])[0] == state.auth.user.email
              ? "right"
              : "left"
          }
        >
          {getMessage(
            Object.keys(messages[key])[0],
            messages[key],
            Object.keys(messages[key])[0] == state.auth.user.email
              ? true
              : false,
            key,
            chatMessages[selectedContactIndex]["chat_id"]
          )}
          <AlwaysScrollToBottom />
        </ListItemText>
      ));
    }
    return newMessages;
  }

  if (state.auth.messages) {
    chats = state.auth.messages.map((chat, index) => (
      <ListItem
        button
        style={{
          width: "110%",
          backgroundColor:
            chat[Object.keys(chat)[0]][state.auth.user.email] &&
            chat[Object.keys(chat)[0]][state.auth.user.email] != 0
              ? "#ECFFDC"
              : Object.keys(chat)[0] == selectedContact
              ? "#d7ecff"
              : "",
        }}
        onClick={() =>
          handleContactClick(
            Object.keys(chat)[0],
            index,
            chat[Object.keys(chat)[0]]["user2"] ==
              state.auth.user.firstname + " " + state.auth.user.lastname
              ? chat[Object.keys(chat)[0]]["user1"]
              : chat[Object.keys(chat)[0]]["user2"]
          )
        }
      >
        <ListItemIcon>
          <Avatar
            alt={
              chat[Object.keys(chat)[0]]["user2"] ==
              state.auth.user.firstname + " " + state.auth.user.lastname
                ? chat[Object.keys(chat)[0]]["user1"]
                : chat[Object.keys(chat)[0]]["user2"]
            }
            src={API_URL + "/media/" + chat["image"]}
          />
        </ListItemIcon>
        <div>
          <ListItemText>
            {chat[Object.keys(chat)[0]]["user2"] ==
            state.auth.user.firstname + " " + state.auth.user.lastname
              ? chat[Object.keys(chat)[0]]["user1"]
              : chat[Object.keys(chat)[0]]["user2"]}
          </ListItemText>
          <div style={{ marginTop: "-10px", fontSize: "16px", color: "#888" }}>
            {chat["subtitle"]}
          </div>
        </div>
        {chat[Object.keys(chat)[0]][state.auth.user.email] &&
        chat[Object.keys(chat)[0]][state.auth.user.email] != 0 ? (
          <div
            style={{
              marginTop: "-13px",
              marginLeft: "10px",
              height: "20px",
              width: "20px",
              backgroundColor: "#3182bd",
              borderRadius: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "6px",
                marginTop: "0px",
                color: "white",
                fontSize: "14px",
              }}
            >
              {chat[Object.keys(chat)[0]][state.auth.user.email]}
            </div>
          </div>
        ) : (
          ""
        )}
      </ListItem>
    ));
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (state.auth.expiry >= new Date()) {
          if (state.auth.message != null) {
            const dataFromServer = state.auth.message;
            dispatch({ type: "TEXT_MESSAGE", payload: null });

            var allMessages = state.auth.messages;

            for (var i = 0; i < allMessages.length; i++) {
              if (
                Object.keys(allMessages[i])[0] == dataFromServer["receiver"]
              ) {
                var messages = allMessages[i][dataFromServer["receiver"]];
                var maxKey =
                  Math.max.apply(
                    null,
                    Object.keys(
                      allMessages[i][dataFromServer["receiver"]]["messages"]
                    )
                  ) + 1;

                var a = {};
                a[state.auth.user.email] = dataFromServer["message"];
                a["time"] = dataFromServer["time"];
                a["date"] = dataFromServer["date"];
                a["type"] = dataFromServer["type"];
                messages["messages"][maxKey] = a;

                allMessages[i][dataFromServer["receiver"]] = messages;
                break;
              } else if (
                Object.keys(allMessages[i])[0] == dataFromServer["sender"]
              ) {
                var messages = allMessages[i][dataFromServer["sender"]];
                var maxKey =
                  Math.max.apply(
                    null,
                    Object.keys(
                      allMessages[i][dataFromServer["sender"]]["messages"]
                    )
                  ) + 1;
                var a = {};
                a[dataFromServer["sender"]] = dataFromServer["message"];
                a["time"] = dataFromServer["time"];
                a["date"] = dataFromServer["date"];
                a["type"] = dataFromServer["type"];
                messages["messages"][maxKey] = a;

                allMessages[i][dataFromServer["sender"]] = messages;
                break;
              }
            }
            setChatMessages(allMessages);
            setTextMessage("a");
            setTextMessage("");
            setIsSending(false);
          }

          if (!state.auth.isLoadingMessages) {
            if (loading) {
              setChatMessages(state.auth.messages);
              if (state.auth.messages.length != 0) {
                setSelectedContact(Object.keys(state.auth.messages[0])[0]);
                setSelectedContactIndex(0);
                setSelectedContactName(
                  state.auth.messages[0][
                    Object.keys(state.auth.messages[0])[0]
                  ]["user2"] ==
                    state.auth.user.firstname + " " + state.auth.user.lastname
                    ? state.auth.messages[0][
                        Object.keys(state.auth.messages[0])[0]
                      ]["user1"]
                    : state.auth.messages[0][
                        Object.keys(state.auth.messages[0])[0]
                      ]["user2"]
                );
              }
              setLoading(false);
            }

            return (
              <Fragment>
                <Sidebar />
                <Menu
                  anchorEl={anchorEl}
                  id={"menu"}
                  keepMounted
                  open={openMenu}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setOpen2(true);
                      setAnchorEl(null);
                    }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={() => setOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      {fileData()}
                    </div>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2}
                  onClose={() => setOpen2(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px" }}>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton size="small">
                            <CloseIcon
                              onClick={() => setOpen2(false)}
                              style={{ color: "#3182bd" }}
                            />
                          </IconButton>
                        </div>
                        <h3>Delete this message?</h3>
                        <span>
                          This action cannot be reversed. It will be deleted
                          forever.
                        </span>
                        <br></br>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => deleteMessage()}
                            style={{ height: "30px" }}
                            variant="contained"
                          >
                            Yes
                          </Button>
                          <Button
                            style={{ height: "30px", marginLeft: "10px" }}
                            variant="contained"
                            onClick={() => setOpen2(false)}
                          >
                            No
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                <div
                  style={{
                    margin: "64px 0px 0px 70px",
                    backgroundColor: "white",
                    overflow: "hidden",
                    height: window.innerHeight - 70 + "px",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                      float: "left",
                      overflowX: "scroll",
                      height: "100vh",
                    }}
                  >
                    <div
                      style={{
                        margin: "60px 0px 0px -8px",
                        overflow: "hidden",
                        padding: "10px",
                      }}
                    >
                      {chats}
                    </div>
                  </div>

                  <div
                    style={{
                      height: "100vh",
                      width: "75%",
                      float: "left",
                      overflow: "scroll",
                      borderLeft: "1px solid #ddd",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "80px",
                        minHeight: window.innerHeight - 70 - 70 + "px",
                      }}
                    >
                      {getMessages()}
                      <div style={{ height: "100px" }}></div>
                    </div>
                    <Grid
                      container
                      spacing={1}
                      style={{
                        width: "100.5%",
                        backgroundColor: "white",
                        zIndex: 99,
                        bottom: 0,
                        position: "sticky",
                        borderTop: "1px solid #ddd",
                      }}
                    >
                      <Grid item xs={1}>
                        <label htmlFor="contained-button-file">
                          <input
                            style={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={(e) => onFileChange(e)}
                          />

                          <Button
                            style={{
                              height: "40px",
                              boxShadow: "none",
                              left: 3,
                            }}
                            variant="contained"
                            component="span"
                          >
                            <AttachFileIcon style={{ marginTop: "5px" }} />
                          </Button>
                        </label>
                      </Grid>
                      <Grid item xs={10}>
                        <InputBase
                          value={textMessage}
                          onChange={(e) => handleTextChange(e)}
                          placeholder="Type Something"
                          style={{
                            zIndex: 99,
                            width: "100%",
                            bottom: -3,
                            fontSize: 19,
                          }}
                          inputProps={{ "aria-label": "search google maps" }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          onClick={() => sendMessage()}
                          style={{
                            height: "40px",
                            boxShadow: "none",
                            position: "absolute",
                            right: 8,
                          }}
                          color="primary"
                          aria-label="send"
                          variant="contained"
                        >
                          {isSending ? (
                            <CircularProgress
                              size={25}
                              style={{ color: "white" }}
                            />
                          ) : (
                            <SendIcon />
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Fragment>
            );
          } else {
            return (
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Messenger;
