import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import { Line } from "react-chartjs-2";
import Paper from "@mui/material/Paper";
import PublicIcon from "@mui/icons-material/Public";
import FunctionsIcon from "@mui/icons-material/Functions";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { loadUser } from "../actions/auth";
import html2pdf from "html2pdf.js";
import AddIcon from "@mui/icons-material/Add";

function Ranking() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [selectedFile, setSelectedFile] = useState("");
  const [achievement, setAchievement] = useState("");
  const [description, setDescription] = useState("");
  const [documents, setDocuments] = useState("");
  const [open, setOpen] = React.useState(false);
  const [chartX, setChartX] = useState([]);
  const [chartY, setChartY] = useState([]);
  const [addAchievement, setAddAchievement] = useState(false);

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (Object.keys(JSON.parse(state.auth.user.rank_history)).length != 0) {
        setRank(JSON.parse(state.auth.user.rank_history));
        var tempRanking = JSON.parse(state.auth.user.rank_history);
        var tempChartX = [];
        var tempChartY = [];
        for (
          var i = 0;
          i < tempRanking[state.auth.current_year]["history"].length;
          i++
        ) {
          tempChartX.push(0);
          tempChartY.push(0);
          tempChartX.push(
            tempRanking[state.auth.current_year]["history"][i]["points"]
          );
          tempChartY.push(i + 1);
          setChartX(tempChartX);
          setChartY(tempChartY);
        }
      }
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    dispatch(loadUser());
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  const graph = {
    dataLine: {
      labels: chartY,
      datasets: [
        {
          label: "Holistic ranking",
          fill: true,
          lineTension: 0.3,
          backgroundColor: "rgba(225, 204,230, .3)",
          borderColor: "red",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "red",
          pointBackgroundColor: "red",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "red",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: chartX,
        },
      ],
    },
  };

  function downloadPDF() {
    document.getElementById("pdf-element").style.display = "block";
    html2pdf(document.getElementById("pdf-element"));
  }

  function getSize(fileSize) {
    if (fileSize < 1024) {
      return <p>Size: {(fileSize / 1024).toFixed(2)} B</p>;
    } else if (fileSize > 1024 && fileSize < 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} KB</p>;
    } else if (fileSize > 1024 * 1024 && fileSize < 25 * 1024 * 1024) {
      return <p>Size: {(fileSize / (1024 * 1024)).toFixed(2)} MB</p>;
    } else if (fileSize > 25 * 1024 * 1024) {
      handleClose();
      createAlert(
        "ERROR",
        "File too big.",
        "File size should be less than 25 MB."
      );
    }
  }

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>

          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function onFileUpload() {
    if (
      achievement.length == 0 ||
      documents.length == 0 ||
      description.length == 0
    ) {
      createAlert(
        "ERROR",
        "Error",
        "Please fill all the fields before uploading."
      );
    } else {
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append("file", selectedFile, selectedFile.name);

      // Details of the uploaded file
      console.log(selectedFile);

      // Request made to the backend api
      // Send formData object

      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          // "Content-Type": "application/json",
        },
      };

      var date = new Date();

      formData.append("email", state.auth.user.email);
      formData.append("achievement", achievement);
      formData.append("documents", documents);
      formData.append("description", description);

      axios
        .post(API_URL + "/add_rank", formData, config)
        .then(() => {
          createAlert("SUCCESS", "Success.", "Documents uploaded.");
        })
        .catch((err) => {
          console.log("___");
          console.log(err);
          createAlert("ERROR", "Error", "An error occured.");
        });

      handleClose();
      document.getElementById("contained-button-file").value = "";
    }
  }

  function onFileChange(event) {
    // Update the state
    setSelectedFile(event.target.files[0]);

    handleOpen();
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  const [ranking, setRank] = useState([]);

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (state.auth.expiry >= new Date()) {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "right",
                    }}
                  >
                    <Button
                      onClick={() => setAddAchievement(true)}
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "15px",
                        backgroundColor: "#ff3333",
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </Button>
                  </div>
                }
              />
              <Modal
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    {fileData()}
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "72px 20px 20px 90px",
                  backgroundColor: "white",
                }}
              >
                {addAchievement ? (
                  <Grid container spacing={1.5}>
                    <Grid item md={5}>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Achievement
                      </span>
                      <br></br>
                      <TextField
                        style={{ marginTop: "2px", marginBottom: "15px" }}
                        value={achievement}
                        onChange={(e) => setAchievement(e.target.value)}
                        fullWidth
                        placeholder="Achievement"
                        variant="outlined"
                        size="small"
                      />
                      <div style={{ marginTop: "-16px" }}>
                        <span
                          style={{
                            color: "#3182bd",
                            fontFamily: "Lato-Regular",
                            fontSize: "14px",
                          }}
                        >
                          Documents
                        </span>
                        <br></br>
                        <TextField
                          style={{ marginTop: "0px", marginBottom: "15px" }}
                          fullWidth
                          value={documents}
                          onChange={(e) => setDocuments(e.target.value)}
                          placeholder="Documents"
                          variant="outlined"
                          size="small"
                        />
                      </div>
                    </Grid>
                    <Grid item md={5}>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Description of your documents
                      </span>
                      <br></br>
                      <TextField
                        style={{ marginTop: "2px", marginBottom: "15px" }}
                        multiline
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows={3}
                        fullWidth
                        placeholder="Description"
                        variant="outlined"
                      />
                    </Grid>{" "}
                    <Grid
                      item
                      md={2}
                      style={{ display: "flex", flexDirection: "column" }}
                      alignItems="center"
                      justifyContent={"center"}
                    >
                      <label
                        style={{ width: "100%" }}
                        htmlFor="contained-button-file"
                      >
                        <input
                          style={{ display: "none" }}
                          id="contained-button-file"
                          type="file"
                          onChange={(e) => onFileChange(e)}
                        />

                        <Button
                          style={{
                            width: "100%",
                            fontSize: "15px",
                            height: "35px",
                            marginTop: "-1px",
                            backgroundColor: "#3182bd",
                          }}
                          variant="contained"
                          component="span"
                        >
                          <h4>Upload</h4>
                        </Button>
                      </label>
                      <Button
                        onClick={() => setAddAchievement(false)}
                        style={{
                          height: "35px",
                          marginTop: "10px",
                        }}
                      >
                        <h4>Hide</h4>
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}

                <h3>Holistic Ranking</h3>
                {Object.keys(JSON.parse(state.auth.user.rank_history)).length !=
                0 ? (
                  <Fragment>
                    <Box style={{ marginTop: "-30px", width: "100%" }}>
                      <Line
                        height={90}
                        width={200}
                        data={graph.dataLine}
                        options={{ responsive: true }}
                      />
                    </Box>
                    <br></br> <br></br> <h3>My Ranking History</h3>
                    <Grid container spacing={2}>
                      {Object.values(ranking).map((rank, index) => (
                        <Grid item md={3}>
                          <Box
                            fullWidth
                            style={{
                              boxShadow: "0 2px 6px 0 rgba(0,0,0,0.2)",
                              color: "white",
                              backgroundColor: "white",
                              borderRadius: "6px",
                              minHeight: "100px",
                              padding: "15px",
                              alignItems: "center",
                              border: "1.5px solid #3283c9",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                color: "white",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                justifyContent: "center",
                              }}
                            >
                              <Box
                                textAlign={"center"}
                                style={{
                                  borderRadius: "40px",
                                  backgroundColor: "#3283c9",
                                  width: "180px",
                                  height: "50px",
                                  marginTop: "10px",
                                }}
                              >
                                <div
                                  style={{
                                    color: "white",
                                    padding: "5px",
                                    marginTop: "2px",
                                    fontSize: "24px",
                                    textAlign: "center",
                                    fontFamily: "Bahnschrift",
                                    letterSpacing: "2px",
                                  }}
                                >
                                  {Object.keys(ranking)[index]}
                                </div>
                              </Box>
                            </Box>
                            <Box
                              style={{
                                marginTop: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                fontSize: "20px",
                                padding: "10px",
                                fontFamily: "Roboto-Regular",
                              }}
                            >
                              <span
                                style={{
                                  color: "black",
                                  textAlign: "left",
                                  fontFamily: "Roboto-Regular",
                                }}
                              >
                                {rank["points"]} Points
                              </span>
                              <Box style={{ width: 10 }}></Box>
                              <span
                                style={{
                                  color: "black",
                                  textAlign: "right",
                                }}
                              >
                                Rank {rank["rank"]}
                              </span>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    <br></br>
                    <Box
                      style={{
                        margin: "100px 0px 25px 0px",
                      }}
                    ></Box>
                    {Object.keys(ranking).map((year) => (
                      <Fragment>
                        <span
                          style={{
                            marginTop: 30,
                            fontSize: "25px",
                            color: "black",
                            fontFamily: "Bahnschrift",
                            letterSpacing: "2px",
                          }}
                        >
                          {year}
                        </span>
                        <br></br>
                        <div
                          style={{
                            color: "grey",
                            fontSize: "16px",
                            marginTop: "-10px",
                            fontFamily: "Roboto-Regular",
                          }}
                        >
                          {ranking[year]["points"]} Points, Rank{" "}
                          {ranking[year]["rank"]}
                        </div>
                        <br></br>
                        <br></br>

                        <Grid container spacing={2}>
                          {ranking[year]["history"].map((rank) => (
                            <Grid item md={4}>
                              <Box
                                style={{
                                  color: "white",
                                  backgroundColor: "white",
                                  borderRadius: "6px",
                                  padding: "15px",
                                  alignItems: "center",
                                  borderRadius: "6px",
                                  border: "3px solid #d7ecff",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Box>
                                    <div
                                      style={{
                                        color: "#084594",
                                        fontSize: "20px",
                                        fontFamily: "Lato-Bold",
                                        marginBottom: "-5px",
                                      }}
                                    >
                                      {rank["points"] == "0"
                                        ? "Pending Approval"
                                        : rank["points"] + " Points"}{" "}
                                    </div>
                                    <span style={{ color: "black" }}>
                                      {rank["achievement"]}
                                    </span>
                                  </Box>
                                  <Box>
                                    <Box
                                      style={{
                                        backgroundColor:
                                          rank["reviewed"] != null
                                            ? rank["reviewed"]
                                              ? "#31a354"
                                              : "#f44336"
                                            : "#3283c9",
                                        padding: 2,
                                        height: "30px",
                                        width: "30px",
                                        borderRadius: "30px",
                                      }}
                                    >
                                      {rank["reviewed"] != null ? (
                                        rank["reviewed"] ? (
                                          <CheckIcon
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "26px",
                                            }}
                                          />
                                        ) : (
                                          <CloseIcon
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "26px",
                                            }}
                                          />
                                        )
                                      ) : (
                                        <AccessTimeIcon
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "26px",
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </Box>
                                </Box>

                                <Box style={{ height: 15 }}></Box>
                                {rank["documents"].map((document) => (
                                  <div>
                                    <a
                                      target="_blank"
                                      style={{
                                        textDecoration: "none",
                                      }}
                                      href={document["link"]}
                                    >
                                      <Button
                                        style={{
                                          width: "100%",
                                          backgroundColor: "white",
                                          borderRadius: "6px",
                                          marginBottom: "10px",

                                          border: "1px solid #ddd",
                                          flexDirection: "row",
                                          justifyContent: "space-between",
                                          paddingLeft: "10px",
                                          height: "35px",
                                        }}
                                      >
                                        <span
                                          style={{
                                            marginTop: "2px",
                                            textAlignVertical: "center",
                                            color: "#3182bd",
                                          }}
                                        >
                                          {document["document"]}
                                        </span>

                                        <Box
                                          style={{
                                            padding: "5px",
                                            height: "35px",
                                            width: "60px",
                                            backgroundColor: "#3283c9",
                                            borderTopRightRadius: "6px",
                                            borderBottomRightRadius: "6px",
                                            marginRight: "-10px",
                                          }}
                                        >
                                          <div
                                            style={{
                                              color: "white",
                                              textAlign: "center",
                                              fontFamily: "Bahnschrift",
                                              marginTop: "2px",
                                            }}
                                          >
                                            VIEW
                                          </div>
                                        </Box>
                                      </Button>
                                    </a>
                                  </div>
                                ))}
                              </Box>
                            </Grid>
                          ))}
                        </Grid>

                        <br></br>
                        <br></br>
                      </Fragment>
                    ))}
                  </Fragment>
                ) : (
                  <h2>No ranking data available</h2>
                )}
                {Object.keys(JSON.parse(state.auth.user.rank_history)).length !=
                0 ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "100px",
                      backgroundColor: "#d7ecff",
                      padding: "20px",
                    }}
                  >
                    <Button
                      style={{ height: "40px" }}
                      variant="contained"
                      onClick={() => {
                        downloadPDF();
                      }}
                    >
                      <h2>Download PDF</h2>
                    </Button>{" "}
                  </div>
                ) : (
                  ""
                )}
                <div
                  style={{
                    padding: "50px",
                    marginTop: "-20px",
                  }}
                  id={"pdf-element"}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "-20px 0px -20px 0px",
                    }}
                  >
                    <h2 style={{ color: "black" }}>
                      {state.auth.user.firstname}{" "}
                      {state.auth.user.middlename
                        ? state.auth.user.middlename
                        : ""}{" "}
                      {state.auth.user.lastname}
                    </h2>
                    <a
                      style={{ textDecoration: "none" }}
                      href={"https://student.elemzy.com"}
                    >
                      <img
                        style={{ maxHeight: "50px", margin: "10px" }}
                        src={require("../img/appbar.png")}
                      />
                    </a>
                  </div>
                  <h3>Holistic Ranking</h3>
                  {Object.keys(JSON.parse(state.auth.user.rank_history))
                    .length != 0 ? (
                    <Fragment>
                      <Box style={{ marginTop: "-30px", width: "700px" }}>
                        <Line
                          height={100}
                          width={100}
                          data={graph.dataLine}
                          options={{ responsive: true }}
                        />
                      </Box>
                      <br></br> <br></br> <h3>My Ranking History</h3>
                      <Grid container spacing={2}>
                        {Object.values(ranking).map((rank, index) => (
                          <Grid item md={6}>
                            <Box
                              fullWidth
                              style={{
                                boxShadow: "0 2px 6px 0 rgba(0,0,0,0.2)",
                                color: "white",
                                backgroundColor: "white",
                                borderRadius: "6px",
                                minHeight: "100px",
                                padding: "15px",
                                alignItems: "center",
                                border: "1.5px solid #3283c9",
                              }}
                            >
                              <Box
                                style={{
                                  display: "flex",
                                  color: "white",
                                  backgroundColor: "white",
                                  borderRadius: "6px",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  textAlign={"center"}
                                  style={{
                                    borderRadius: "40px",
                                    backgroundColor: "#3283c9",
                                    width: "180px",
                                    height: "50px",
                                    marginTop: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      color: "white",
                                      padding: "5px",
                                      marginTop: "2px",
                                      fontSize: "24px",
                                      textAlign: "center",
                                      fontFamily: "Bahnschrift",
                                      letterSpacing: "2px",
                                    }}
                                  >
                                    {Object.keys(ranking)[index]}
                                  </div>
                                </Box>
                              </Box>
                              <Box
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  fontSize: "20px",
                                  padding: "10px",
                                  fontFamily: "Roboto-Regular",
                                }}
                              >
                                <span
                                  style={{
                                    color: "black",
                                    textAlign: "left",
                                    fontFamily: "Roboto-Regular",
                                  }}
                                >
                                  {rank["points"]} Points
                                </span>
                                <Box style={{ width: 10 }}></Box>
                                {console.log(rank)}
                                <span
                                  style={{
                                    color: "black",
                                    textAlign: "right",
                                  }}
                                >
                                  Rank {rank["rank"]}
                                </span>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <br></br>
                      <Box
                        style={{
                          margin: "100px 0px 25px 0px",
                        }}
                      ></Box>
                      {Object.keys(ranking).map((year) => (
                        <Fragment>
                          <span
                            style={{
                              marginTop: 30,
                              fontSize: "25px",
                              color: "black",
                              fontFamily: "Bahnschrift",
                              letterSpacing: "2px",
                            }}
                          >
                            {year}
                          </span>
                          <br></br>
                          <div
                            style={{
                              color: "grey",
                              fontSize: "16px",
                              marginTop: "-10px",
                              fontFamily: "Roboto-Regular",
                            }}
                          >
                            {ranking[year]["points"]} Points, Rank{" "}
                            {ranking[year]["rank"]}
                          </div>
                          <br></br>
                          <br></br>

                          <Grid container spacing={2}>
                            {ranking[year]["history"].map((rank) => (
                              <Fragment>
                                {rank["reviewed"] != null &&
                                rank["reviewed"] != false ? (
                                  <Grid item md={6}>
                                    <Box
                                      style={{
                                        color: "white",
                                        backgroundColor: "white",
                                        borderRadius: "6px",
                                        padding: "15px",
                                        alignItems: "center",
                                        borderRadius: "6px",
                                        border: "3px solid #d7ecff",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Box>
                                          <div
                                            style={{
                                              color: "#084594",
                                              fontSize: "20px",
                                              fontFamily: "Lato-Bold",
                                              marginBottom: "-5px",
                                            }}
                                          >
                                            {rank["points"] + " Points"}
                                          </div>
                                          <span style={{ color: "black" }}>
                                            {rank["achievement"]}
                                          </span>
                                        </Box>
                                        <Box>
                                          <Box
                                            style={{
                                              backgroundColor:
                                                rank["reviewed"] != null
                                                  ? rank["reviewed"]
                                                    ? "#31a354"
                                                    : "#f44336"
                                                  : "#3283c9",
                                              padding: 2,
                                              height: "30px",
                                              width: "30px",
                                              borderRadius: "30px",
                                            }}
                                          >
                                            {rank["reviewed"] != null ? (
                                              rank["reviewed"] ? (
                                                <CheckIcon
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "26px",
                                                  }}
                                                />
                                              ) : (
                                                <CloseIcon
                                                  style={{
                                                    fontWeight: "bold",
                                                    fontSize: "26px",
                                                  }}
                                                />
                                              )
                                            ) : (
                                              <AccessTimeIcon
                                                style={{
                                                  fontWeight: "bold",
                                                  fontSize: "26px",
                                                }}
                                              />
                                            )}
                                          </Box>
                                        </Box>
                                      </Box>

                                      <Box style={{ height: 15 }}></Box>
                                      {rank["documents"].map((document) => (
                                        <div>
                                          <a
                                            target="_blank"
                                            style={{
                                              textDecoration: "none",
                                            }}
                                            href={document["link"]}
                                          >
                                            <Button
                                              style={{
                                                width: "100%",
                                                backgroundColor: "white",
                                                borderRadius: "6px",
                                                marginBottom: "10px",

                                                border: "1px solid #ddd",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                paddingLeft: "10px",
                                                height: "35px",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  marginTop: "2px",
                                                  textAlignVertical: "center",
                                                  color: "#3182bd",
                                                }}
                                              >
                                                {document["document"]}
                                              </span>

                                              <Box
                                                style={{
                                                  padding: "5px",
                                                  height: "35px",
                                                  width: "60px",
                                                  backgroundColor: "#3283c9",
                                                  borderTopRightRadius: "6px",
                                                  borderBottomRightRadius:
                                                    "6px",
                                                  marginRight: "-10px",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    color: "white",
                                                    textAlign: "center",
                                                    fontFamily: "Bahnschrift",
                                                    marginTop: "2px",
                                                  }}
                                                >
                                                  VIEW
                                                </div>
                                              </Box>
                                            </Button>
                                          </a>
                                        </div>
                                      ))}
                                    </Box>
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Fragment>
                            ))}
                          </Grid>

                          <br></br>
                          <br></br>
                        </Fragment>
                      ))}
                    </Fragment>
                  ) : (
                    <h2>No ranking data available</h2>
                  )}
                </div>
              </div>
            </Fragment>
          );
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Ranking;
