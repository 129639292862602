import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import moment from "moment";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Notifications() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [randomText, setRandom] = useState(makeid(4));

  function deleteNotification(notification_id) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body["notification_id"] = notification_id;
    body = JSON.stringify(body);

    axios.post(API_URL + "/delete_notification", body, config).then((res) => {
      getData();
    });
  }

  function updateNotificationSeen(notification_id) {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    var body = {
      email: state.auth.user.email,
      notification_id: notification_id,
    };
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/update_notification_seen", body, config)
      .then((res) => {
        getData();
      });
  }

  function clearAllNotifications() {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {
      email: state.auth.user.email,
      notifications: notificationList,
    };
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/clear_all_notifications", body, config)
      .then((res) => {});
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["email"] = state.auth.user.email;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_all_user_notifications", body, config)
      .then((res) => {
        setNotificationList(res.data);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      setIsReady(true);
      clearAllNotifications();
      getData();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    clearAllNotifications();
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  var notiDate = "";
  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (state.auth.expiry >= new Date()) {
          if (isLoading || !isReady) {
            return (
              <Fragment>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginTop: "46vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    margin: "55px 10px 20px 90px",
                    backgroundColor: "white",
                  }}
                >
                  {(notiDate = "")}
                  {notificationList.length == 0 ? (
                    <div style={{ marginTop: "80px" }}>
                      <span style={{ fontSize: "25px" }}>
                        No notifications to show.
                      </span>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        {notificationList.map((notification, index) => (
                          <div style={{ width: "80%" }}>
                            {notiDate == notification["date"] ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span style={{ display: "none" }}>
                                  {(notiDate = notification["date"])}
                                </span>
                                <Link
                                  to={{
                                    pathname: "/" + notification["page"],
                                    search: "?tab=" + notification["tab"],
                                  }}
                                  style={{ textDecoration: "none" }}
                                >
                                  <CardActionArea
                                    sx={[
                                      {
                                        borderRadius: "6px",
                                        border: "1.5px solid #d7ecff",
                                        transition:
                                          "background-color 0.5s, border-color 0.5s",
                                      },
                                      (theme) => ({
                                        "&:hover": {
                                          backgroundColor: "#ebf5ff",
                                          borderColor: "#3182bd",
                                        },
                                        ".MuiCardActionArea-focusHighlight": {
                                          background: "transparent",
                                        },

                                        "&& .MuiTouchRipple-child": {
                                          backgroundColor: "#084594",
                                        },
                                      }),
                                    ]}
                                    style={{
                                      padding: "7px",
                                      width: "50vw",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div
                                        style={{
                                          marginTop: "-25px",
                                          color: "#3182bd",
                                        }}
                                      >
                                        <h4>{notification["title"]}</h4>
                                        <div
                                          style={{
                                            marginTop: "-20px",
                                            color: "black",
                                          }}
                                        >
                                          {notification["body"]}
                                        </div>
                                      </div>
                                    </div>
                                  </CardActionArea>
                                </Link>

                                <Button
                                  onClick={() =>
                                    deleteNotification(notification["id"])
                                  }
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50px",
                                  }}
                                >
                                  <DeleteIcon
                                    style={{
                                      marginTop: "-3px",
                                      color: "#ff3333",
                                    }}
                                  />
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <span style={{ display: "none" }}>
                                  {(notiDate = notification["date"])}
                                </span>{" "}
                                <div
                                  style={{
                                    marginLeft: "10px",
                                    color: "#3182bd",
                                    marginBottom: "5px",
                                    fontSize: "13px",
                                    marginTop: "15px",
                                    fontWeight: "bold",
                                    fontFamily: "Lato-Regular",
                                  }}
                                >
                                  {moment(
                                    notification["date"],
                                    "DD-MM-YYYY"
                                  ).format("Do MMMM, YYYY")}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Link
                                    to={{
                                      pathname: "/" + notification["page"],
                                      search: "?tab=" + notification["tab"],
                                    }}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <CardActionArea
                                      sx={[
                                        {
                                          borderRadius: "6px",
                                          border: "1.5px solid #d7ecff",
                                          transition:
                                            "background-color 0.5s, border-color 0.5s",
                                        },
                                        (theme) => ({
                                          "&:hover": {
                                            backgroundColor: "#ebf5ff",
                                            borderColor: "#3182bd",
                                          },
                                          ".MuiCardActionArea-focusHighlight": {
                                            background: "transparent",
                                          },

                                          "&& .MuiTouchRipple-child": {
                                            backgroundColor: "#084594",
                                          },
                                        }),
                                      ]}
                                      style={{
                                        padding: "7px",
                                        marginBottom: "10px",
                                        width: "50vw",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          width: "100%",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <div style={{ marginTop: "-25px" }}>
                                          <h4 style={{ color: "#3182bd" }}>
                                            {notification["title"]}
                                          </h4>
                                          <div
                                            style={{
                                              marginTop: "-20px",
                                              color: "black",
                                            }}
                                          >
                                            {notification["body"]}
                                          </div>
                                        </div>
                                      </div>
                                    </CardActionArea>
                                  </Link>

                                  <Button
                                    onClick={() =>
                                      deleteNotification(notification["id"])
                                    }
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      borderRadius: "50px",
                                    }}
                                  >
                                    <DeleteIcon
                                      style={{
                                        marginTop: "-3px",
                                        color: "#ff3333",
                                      }}
                                    />
                                  </Button>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Notifications;
