import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import moment from "moment";
import html2pdf from "html2pdf.js";
import Button from "@mui/material/Button";

function Attendance() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDateChanged(true);
  };

  function handleClear() {
    setDatewise(!datewise);
    setDateChanged(false);
  }
  const [isLoading, setIsLoading] = useState(true);
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [attendance, setAttendance] = useState({});
  const [allAttendance, setAllAttendance] = useState({});

  const [currentSemKey, setCurrentSemKey] = useState(0);
  const [currentSubject, setCurrentSubject] = useState("All Subjects");
  const [semesterList, setSemesterList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);

  const [datewise, setDatewise] = useState(false);
  const [dateChanged, setDateChanged] = useState(false);

  const [logo, setLogo] = useState(false);

  function getData() {
    setSubjectList(JSON.parse(state.auth.user.subject_list));
    var myAttendance = JSON.parse(state.auth.user.attendance);
    var maxKey =
      Object.keys(myAttendance)[Object.keys(myAttendance).length - 1];
    setCurrentSemKey(maxKey);
    setAttendance(myAttendance[maxKey]);
    setAllAttendance(myAttendance);
    var semList = [];
    for (var i = 0; i < Object.keys(myAttendance).length; i++) {
      semList.push(Object.keys(myAttendance)[i]);
    }
    var subList = JSON.parse(state.auth.user.subject_list);
    subList.splice(0, 0, "All Subjects");

    setSemesterList(semList);
    setSubjectList(subList);

    setIsLoading(false);
  }

  const handleSemesterChange = (event) => {
    setCurrentSemKey(event.target.value);
    setAttendance(allAttendance[event.target.value]);
  };

  const handleSubjectChange = (event) => {
    setCurrentSubject(event.target.value);
  };

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getSemesters() {
    var sems = semesterList.map((sem, index) => (
      <MenuItem value={sem}>{sem}</MenuItem>
    ));

    return sems;
  }

  function getSubjects() {
    var subs = subjectList.map((sub, index) => (
      <MenuItem value={sub}>{sub}</MenuItem>
    ));

    return subs;
  }

  async function getLogo() {
    const base64 = await fetch(
      API_URL + "/media/" + state.auth.user.institution_id + ".jpg"
    )
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((res) => {
          reader.onloadend = () => {
            res(reader.result);
          };
        });
      })
      .then((res) => {
        setLogo(res);
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
      getLogo();
    }
  }, []);

  function getIfEmpty(attendanceDict) {
    var attendanceSubjects = [];
    for (var i = 0; i < Object.values(attendanceDict).length; i++) {
      for (
        var j = 0;
        j < Object.keys(Object.values(attendanceDict)[i]).length;
        j++
      ) {
        attendanceSubjects.push(
          Object.keys(Object.values(attendanceDict)[i])[j]
        );
      }
    }

    if (attendanceSubjects.includes(currentSubject)) {
      return true;
    } else {
      return false;
    }
  }

  function getAttendance() {
    if (Object.keys(allAttendance).length == 0) {
      return (
        <div
          style={{
            minHeight: "20px",
            borderRadius: "6px",
            color: "black",
            border: "1.5px solid #d7ecff",
          }}
        >
          <h3 style={{ marginLeft: "15px" }}>
            No previous attendance records found.
          </h3>
        </div>
      );
    } else {
      var dateFormatted = moment(selectedDate).format("DD-MM-YYYY");
      if (datewise) {
        if (currentSubject == "All Subjects") {
          return (
            <Grid container spacing={1.5}>
              {attendance["attendance"][dateFormatted] == null ? (
                <div
                  style={{
                    minHeight: "20px",
                    borderRadius: "6px",
                    color: "black",
                    border: "1.5px solid #d7ecff",
                    marginTop: "12px",
                    width: "100%",
                    marginLeft: "10px",
                  }}
                >
                  <h3 style={{ marginLeft: "15px" }}>
                    No previous attendance records found.
                  </h3>
                </div>
              ) : (
                Object.entries(attendance["attendance"][dateFormatted]).map(
                  ([key, value]) => (
                    <Grid item md={4}>
                      <Box
                        p={2}
                        style={{
                          minHeight: "20px",
                          borderRadius: "6px",
                          color: "black",
                          border: "1.5px solid #d7ecff",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "18px",
                            marginTop: "-5px",
                            fontFamily: "Lato-Bold",
                            color: "#084594",
                          }}
                        >
                          <b>{key}</b>
                        </div>
                        {Object.entries(value).map(([key, value], index) => (
                          <span style={{ fontFamily: "Lato-Bold" }}>
                            <b>
                              {value == true
                                ? index + ". Present"
                                : index + ". Absent"}
                            </b>
                            <br></br>
                          </span>
                        ))}
                      </Box>
                    </Grid>
                  )
                )
              )}
            </Grid>
          );
        } else {
          return (
            <div>
              {dateFormatted in attendance["attendance"] ? (
                <Grid container spacing={1.5}>
                  {currentSubject in attendance["attendance"][dateFormatted] ? (
                    <Grid item md={4}>
                      <Box
                        p={2}
                        style={{
                          minHeight: "20px",
                          borderRadius: "6px",
                          color: "black",
                          border: "1.5px solid #d7ecff",
                          height: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontFamily: "Lato-Bold",
                            fontSize: "20px",
                            color: "#084594",
                            marginTop: "-5px",
                          }}
                        >
                          {moment(selectedDate).format("Do MMMM, YYYY")}
                        </div>

                        <div
                          style={{
                            fontSize: "16px",
                            fontFamily: "Lato-Regular",
                          }}
                        >
                          {Object.entries(
                            attendance["attendance"][dateFormatted][
                              currentSubject
                            ]
                          ).map(([key, value], index) => (
                            <span>
                              <b>
                                {value == true
                                  ? index + ". Present"
                                  : index + ". Absent"}
                              </b>
                              <br></br>
                            </span>
                          ))}
                        </div>
                      </Box>
                    </Grid>
                  ) : (
                    <div
                      style={{
                        minHeight: "20px",
                        borderRadius: "6px",
                        color: "black",
                        border: "1.5px solid #d7ecff",
                        width: "100%",
                        marginLeft: "10px",
                        marginTop: "12px",
                      }}
                    >
                      <h3 style={{ marginLeft: "15px" }}>
                        No previous attendance records found.
                      </h3>
                    </div>
                  )}
                </Grid>
              ) : (
                <div
                  style={{
                    minHeight: "20px",
                    borderRadius: "6px",
                    color: "black",
                    border: "1.5px solid #d7ecff",
                    width: "100%",
                    marginLeft: "-2px",
                  }}
                >
                  <h3 style={{ marginLeft: "15px" }}>
                    No previous attendance records found.
                  </h3>
                </div>
              )}
              <br></br>
            </div>
          );
        }
      } else {
        if (currentSubject == "All Subjects") {
          return (
            <div style={{ marginTop: "-5px" }}>
              <div
                style={{
                  fontSize: "20px",
                  fontFamily: "Bahnschrift",
                }}
              >
                Attendence Percent : <b>{attendance["attendance_percent"]}%</b>
              </div>
              <br></br>
              <Grid container spacing={1.5} style={{ marginTop: "-25px" }}>
                {Object.entries(attendance["subjects"]).map(([key, value]) => (
                  <Grid item md={6}>
                    <Box
                      p={2}
                      style={{
                        minHeight: "20px",
                        borderRadius: "6px",
                        color: "black",
                        border: "1.5px solid #d7ecff",
                        height: "100%",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "Lato-Bold",
                          color: "#084594",
                          fontSize: "20px",
                          marginTop: "-8  px",
                        }}
                      >
                        {key}
                      </div>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent={"space-between"}
                        spacing={0}
                        style={{ marginTop: "-15px" }}
                      >
                        <Grid item md={9}>
                          <div
                            style={{
                              fontSize: "16px",
                              marginTop: "-15px",
                              width: "100%",
                            }}
                          >
                            <Grid
                              container
                              spacing={3}
                              style={{ marginTop: "15px" }}
                            >
                              <Grid item md={6}>
                                <span style={{ fontFamily: "Lato-Regular" }}>
                                  {" "}
                                  Total lectures
                                </span>
                              </Grid>
                              <Grid item md={5}>
                                <b
                                  style={{
                                    fontFamily: "Lato-Regular",
                                    marginLeft: "-10px",
                                  }}
                                >
                                  {" "}
                                  {value["total"]}
                                </b>
                              </Grid>
                            </Grid>

                            <Grid container spacing={3}>
                              <Grid item md={6}>
                                <span style={{ fontFamily: "Lato-Regular" }}>
                                  {" "}
                                  Total attended
                                </span>
                              </Grid>
                              <Grid item md={6}>
                                <b
                                  style={{
                                    fontFamily: "Lato-Regular",
                                    marginLeft: "-10px",
                                  }}
                                >
                                  {" "}
                                  {value["attended"]}
                                </b>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item md={3}>
                          <Box
                            justifyContent="center"
                            alignItems={"center"}
                            style={{
                              display: "flex",
                              fontSize: "20px",
                              height: "35px",
                              lineHeight: "50px",
                              borderRadius: "6px",
                              backgroundColor: "#31a354",
                              color: "black",
                              marginTop: "30px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                color: "white",
                                fontFamily: "Bahnschrift",
                                letterSpacing: "2px",
                                marginTop: "2px",
                              }}
                            >
                              {value["total"] == 0
                                ? 0
                                : (
                                    (value["attended"] / value["total"]) *
                                    100
                                  ).toFixed(1)}
                              %
                            </div>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </div>
          );
        } else {
          if (Object.keys(attendance["attendance"]).length == 0) {
            return (
              <div
                style={{
                  minHeight: "20px",
                  borderRadius: "6px",
                  color: "black",
                  border: "1.5px solid #d7ecff",
                }}
              >
                <h3 style={{ marginLeft: "15px" }}>
                  No previous attendance records found.
                </h3>
              </div>
            );
          } else {
            if (getIfEmpty(attendance["attendance"])) {
              return (
                <Grid container spacing={1.5}>
                  {Object.entries(attendance["attendance"]).map(
                    ([key, value]) => (
                      <Fragment>
                        {currentSubject in value ? (
                          <Grid item md={4}>
                            <Box
                              p={2}
                              style={{
                                minHeight: "20px",
                                borderRadius: "6px",
                                color: "black",
                                border: "1.5px solid #d7ecff",
                                height: "100%",
                              }}
                            >
                              <div
                                style={{
                                  fontSize: "18px",
                                  fontFamily: "Lato-Bold",
                                  color: "#084594",
                                  marginTop: "-5px",
                                }}
                              >
                                <b>
                                  {moment(key, "DD-MM-YYYY").format(
                                    "Do MMMM, YYYY"
                                  )}
                                </b>

                                {Object.entries(value[currentSubject]).map(
                                  ([key, value], index) => (
                                    <div
                                      style={{
                                        color: "black",
                                        fontSize: "15px",
                                      }}
                                    >
                                      {value == true
                                        ? index + ". Present"
                                        : index + ". Absent"}
                                      <br></br>
                                    </div>
                                  )
                                )}
                              </div>
                            </Box>
                          </Grid>
                        ) : (
                          ""
                        )}
                        <br />
                      </Fragment>
                    )
                  )}
                </Grid>
              );
            } else {
              return (
                <div
                  style={{
                    minHeight: "20px",
                    borderRadius: "6px",
                    color: "black",
                    border: "1.5px solid #d7ecff",
                  }}
                >
                  <h3 style={{ marginLeft: "15px" }}>
                    No previous attendance records found.
                  </h3>
                </div>
              );
            }
          }
        }
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  margin: "65px 20px 20px 90px",
                  backgroundColor: "white",
                }}
              >
                <Grid container spacing={3}>
                  <Grid
                    item
                    md={9}
                    style={{
                      margin: "-20px",
                      marginLeft: "-50px",
                      marginRight: "50px",
                    }}
                  >
                    <div
                      style={{ padding: "50px", marginTop: "-20px" }}
                      id={"pdf-element"}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "30px",
                        }}
                      >
                        <a
                          style={{ textDecoration: "none" }}
                          href={"https://student.elemzy.com"}
                        >
                          <img
                            style={{
                              maxHeight: "50px",
                              marginTop: "5px",
                              marginRight: "15px",
                            }}
                            src={require("../img/appbar.png")}
                          />
                        </a>

                        {logo ? (
                          <img
                            style={{
                              maxHeight: "75px",
                              marginTop: "5px",
                              marginRight: "15px",
                            }}
                            src={logo}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <h2
                        style={{
                          width: "100%",
                          textAlign: "center",
                          marginTop: "50px",
                          marginBottom: "100px",
                        }}
                      >
                        {state.auth.institutionName}
                      </h2>
                      {getAttendance()}
                    </div>
                  </Grid>
                  <Grid item md={3}>
                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Select semester
                    </span>

                    <Select
                      value={currentSemKey}
                      onChange={(e) => handleSemesterChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getSemesters()}
                    </Select>

                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Select Subject
                    </span>

                    <Select
                      value={currentSubject}
                      onChange={(e) => handleSubjectChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getSubjects()}
                    </Select>

                    <div
                      style={{
                        display: "inline-flex",
                        marginLeft: "-15px",
                      }}
                    >
                      <Checkbox
                        checked={datewise}
                        onChange={(e) => setDatewise(e.target.checked)}
                        value={1}
                        color={"primary"}
                        style={{ marginTop: "-6px", marginLeft: "3px" }}
                      />
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                          marginTop: "5px",
                        }}
                      >
                        Sort by Date
                      </span>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        value={selectedDate}
                        onChange={handleDateChange}
                        inputFormat={"dd-MM-yyyy"}
                        renderInput={(params) => (
                          <TextField
                            style={{
                              marginTop: "0px",
                              marginBottom: "5px",
                            }}
                            size="small"
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={() => {
                          var element = document.getElementById("pdf-element");
                          html2pdf(element);
                        }}
                      >
                        Download PDF
                      </Button>{" "}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Attendance;
