import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Rating from "@mui/material/Rating";
import LockOpenIcon from "@mui/icons-material/LockOpen";
const useStyles = makeStyles({
  root: {
    minWidth: "89vw",
    backgroundColor: "#d7ecff",
  },
  root2: {
    backgroundColor: "#d7ecff",
  },

  media: {
    height: 140,
  },
  search: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
});

function CoursePage() {
  const classes = useStyles();
  const authstate = useSelector((state) => state);
  if (authstate.auth.isAuthenticated) {
    if (authstate.auth.user.is_student) {
      return (
        <Fragment>
          <Sidebar />

          <div
            style={{
              maxWidth: "89vw",
              marginTop: "60px",
              marginLeft: "8vw",
              backgroundColor: "white",
              height: "100vh",
            }}
          >
            <div
              style={{
                padding: "10px 10px 10px 0px",
                margin: "0px 0px 10px 0px",
              }}
            >
              <Paper
                component="form"
                style={{ width: "88vw" }}
                className={classes.search}
              >
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
                <InputBase
                  style={{ width: "89vw" }}
                  className={classes.input}
                  placeholder="Search Courses"
                  inputProps={{ "aria-label": "Search Courses" }}
                />
                <Button variant="contained" color="primary">
                  <SearchIcon />
                </Button>
              </Paper>
            </div>
            <Grid container fullWidth spacing={2}>
              <Grid item>
                <Box borderRadius="16px" className={classes.root}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    component="fieldset"
                    borderColor="transparent"
                    justifyContent="space-between"
                  >
                    <Box ml={2} p={0} width="60%">
                      <h1>Design Thinking : The Beginner's Guide</h1>
                      <p variant="h6" component="h2">
                        Essential Linear Algebra and Calculus Hands-On in NumPy
                        TensorFlow, and PyTorch
                      </p>
                      <Box
                        display="flex"
                        flexDirection="row"
                        component="fieldset"
                        style={{ height: "12px", marginLeft: "-15px" }}
                        borderColor="transparent"
                      >
                        <Rating name="read-only" value={3} readOnly />
                        <h3 style={{ margin: "-3px 0px 0px 20px" }}>
                          <Typography variant="h6" component="h2">
                            3.0
                          </Typography>
                        </h3>
                      </Box>
                      <br></br>
                      <Typography
                        variant="h4"
                        component="h2"
                        style={{ margin: "0px 0px 0px 0px" }}
                      >
                        &#8377; 872
                      </Typography>
                      <br></br>
                      <p style={{ margin: "0px 0px 0px 0px" }}>
                        Created by Sagar
                      </p>
                    </Box>
                    <img
                      style={{
                        objectFit: "contain",
                        width: "30vw",
                        height: "100%",
                        borderRadius: "20px",
                      }}
                      src="https://images.unsplash.com/photo-1580584126903-c17d41830450?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                    ></img>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={3}>
              <Grid item md={8}>
                <Box borderRadius="16px" className={classes.root2}>
                  <Box minHeight={210} p={3}>
                    <h1
                      variant="h4"
                      component="h2"
                      style={{ margin: "0px 0px 0px 0px" }}
                    >
                      What you'll learn
                    </h1>
                    <br></br>
                    <p style={{ margin: "0px 0px 0px 0px" }}>
                      Essential Linear Algebra and Calculus Hands-On in NumPy
                      TensorFlow, and PyTorch Understand the fundamentals of
                      linear algebra, a critical subject underlying Essential
                      Linear Algebra and Calculus Hands-On in NumPy TensorFlow,
                      and PyTorch Essential Linear Algebra
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item md={4}>
                <Box borderRadius="16px" className={classes.root2}>
                  <Box minHeight={210} p={3}>
                    <h1 fontWeight="500" style={{ margin: "0px 0px 0px 0px" }}>
                      ACCESS TO
                    </h1>
                    <div style={{ margin: "0px 0px 0px 0px" }}>
                      <p>Buy now 8.5 hours on-demand video</p>
                      <p> 1 article Full lifetime access </p>

                      <p> Access on mobile</p>

                      <p> Web Certificate of completion</p>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Box borderRadius="16px" className={classes.root2}>
                  <Box minHeight={100} p={3}>
                    <Grid
                      container
                      spacing={1}
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <h1 style={{ margin: "0px 0px 0px 0px" }}>
                        Course content
                      </h1>
                      <Button
                        style={{
                          backgroundColor: "#084594",
                          color: "white",
                          fontSize: "20px",
                        }}
                      >
                        <LockOpenIcon
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                        <span
                          style={{
                            margin: "0px 10px 0px 10px",
                          }}
                        >
                          BUY NOW
                        </span>
                      </Button>
                    </Grid>
                    <br></br>
                    <Box style={{ filter: " blur(4px)" }}>
                      <p
                        variant="h6"
                        component="h2"
                        style={{
                          margin: "0px 0px 0px 0px",
                          userSelect: "none",
                        }}
                      >
                        Essential Linear Algebra and Calculus Hands-On in NumPy
                        TensorFlow, and PyTorch Understand the fundamentals of
                        linear algebra, a critical subject underlying Essential
                        Linear Algebra and Calculus Hands-On in NumPy
                        TensorFlow, and PyTorch Essential Linear
                        AlgebraEssential Linear Algebra and Calculus Hands-On in
                        NumPy TensorFlow, and PyTorch Understand the
                        fundamentals of linear algebra, a critical subject
                        underlying Essential Linear Algebra and Calculus
                        Hands-On in NumPy TensorFlow, and PyTorch Essential
                        Linear AlgebraEssential Linear Algebra and Calculus
                        Hands-On in NumPy TensorFlow, and PyTorch Understand the
                        fundamentals of linear algebra, a critical subject
                        underlying Essential Linear Algebra and Calculus
                        Hands-On in NumPy TensorFlow, and PyTorch Essential
                        Linear AlgebraEssential Linear Algebra and Calculus
                        Hands-On in NumPy TensorFlow, and PyTorch Understand the
                        fundamentals of linear algebra, a critical subject
                        underlying Essential Linear Algebra and Calculus
                        Hands-On in NumPy TensorFlow, and PyTorch Essential
                        Linear Algebra
                      </p>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/login" />
        </Fragment>
      );
    }
  } else {
    return (
      <Fragment>
        <Navigate to="/login" />
      </Fragment>
    );
  }
}
export default CoursePage;
