import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Rating from "@mui/material/Rating";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";

function Courses() {
  const authstate = useSelector((state) => state);
  const [open, setOpen] = useState(true);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  if (authstate.auth.isAuthenticated) {
    if (authstate.auth.user.is_student) {
      return (
        <Fragment>
          <Sidebar />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            BackdropComponent={Backdrop}
            onClose={handleClose}
            closeAfterTransition
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div>
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton size="small">
                    <CloseIcon
                      onClick={() => setOpen(false)}
                      style={{ color: "#3182bd" }}
                    />
                  </IconButton>
                </div>
                <h2>Coming soon!</h2>
              </div>
            </Fade>
          </Modal>
          <div
            style={{
              maxWidth: "89vw",
              marginTop: "65px",
              marginLeft: "8vw",
              backgroundColor: "white",
              height: "100vh",
            }}
          >
            <div
              style={{
                padding: "5px 10px 10px 0px",
                margin: "0px 0px 10px 0px",
              }}
            >
              <Paper component="form" style={{ width: "88vw" }}>
                <IconButton type="submit" aria-label="search" size="large">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  style={{ width: "88vw" }}
                  placeholder="Search Courses"
                  inputProps={{ "aria-label": "Search Courses" }}
                />
                <Button variant="contained" color="primary">
                  <SearchIcon />
                </Button>
              </Paper>
            </div>
            <Grid container spacing={2}>
              <Grid item md={3} lg={3} xl={3}>
                <Link style={{ textDecoration: "none" }} to="/course" path>
                  <Card>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                    >
                      <CardMedia
                        image="https://images.unsplash.com/photo-1580584126903-c17d41830450?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        title="Contemplative Reptile"
                      />
                      <CardContent style={{ height: "50px" }}>
                        <h2
                          style={{ marginTop: "0px" }}
                          variant="h5"
                          component="h2"
                        >
                          Design Thinking : The Beginner's Guide
                        </h2>
                      </CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        component="fieldset"
                        style={{ height: "12px" }}
                        borderColor="transparent"
                      >
                        <Rating name="read-only" value={3} readOnly />
                        <h3 style={{ margin: "5px 0px 0px 10px" }}>3.0</h3>
                      </Box>
                      <CardActions>
                        <h2 style={{ margin: "0px 0px 10px 10px" }}>
                          &#8377; 872
                        </h2>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Link style={{ textDecoration: "none" }} to="/course" path>
                  <Card>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                    >
                      <CardMedia
                        image="https://images.unsplash.com/photo-1589149098258-3e9102cd63d3?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8YXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        title="Contemplative Reptile"
                      />
                      <CardContent style={{ height: "50px" }}>
                        <h2
                          style={{ marginTop: "0px" }}
                          variant="h5"
                          component="h2"
                        >
                          Design Thinking : The Beginner's Guide
                        </h2>
                      </CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        component="fieldset"
                        style={{ height: "12px" }}
                        borderColor="transparent"
                      >
                        <Rating name="read-only" value={3} readOnly />
                        <h3 style={{ margin: "5px 0px 0px 10px" }}>3.0</h3>
                      </Box>
                      <CardActions>
                        <h2 style={{ margin: "0px 0px 10px 10px" }}>
                          &#8377; 872
                        </h2>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Link style={{ textDecoration: "none" }} to="/course" path>
                  <Card>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                    >
                      <CardMedia
                        image="https://images.unsplash.com/photo-1516110833967-0b5716ca1387?ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YXJ0aWZpY2lhbCUyMGludGVsbGlnZW5jZXxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        title="Contemplative Reptile"
                      />
                      <CardContent style={{ height: "50px" }}>
                        <h2
                          style={{ marginTop: "0px" }}
                          variant="h5"
                          component="h2"
                        >
                          Design Thinking : The Beginner's Guide
                        </h2>
                      </CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        component="fieldset"
                        style={{ height: "12px" }}
                        borderColor="transparent"
                      >
                        <Rating name="read-only" value={3} readOnly />
                        <h3 style={{ margin: "5px 0px 0px 10px" }}>3.0</h3>
                      </Box>
                      <CardActions>
                        <h2 style={{ margin: "0px 0px 10px 10px" }}>
                          &#8377; 872
                        </h2>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Link style={{ textDecoration: "none" }} to="/course" path>
                  <Card>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                    >
                      <CardMedia
                        image="https://images.unsplash.com/photo-1597008641621-cefdcf718025?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mzd8fGFydGlmaWNpYWwlMjBpbnRlbGxpZ2VuY2V8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        title="Contemplative Reptile"
                      />
                      <CardContent style={{ height: "50px" }}>
                        <h2
                          style={{ marginTop: "0px" }}
                          variant="h5"
                          component="h2"
                        >
                          Design Thinking : The Beginner's Guide
                        </h2>
                      </CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        component="fieldset"
                        style={{ height: "12px" }}
                        borderColor="transparent"
                      >
                        <Rating name="read-only" value={3} readOnly />
                        <h3 style={{ margin: "5px 0px 0px 10px" }}>3.0</h3>
                      </Box>
                      <CardActions>
                        <h2 style={{ margin: "0px 0px 10px 10px" }}>
                          &#8377; 872
                        </h2>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
              <Grid item md={3} lg={3} xl={3}>
                <Link style={{ textDecoration: "none" }} to="/course" path>
                  <Card>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                    >
                      <CardMedia
                        image="https://images.unsplash.com/photo-1512758017271-d7b84c2113f1?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZGVzaWduJTIwdGhpbmtpbmd8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
                        title="Contemplative Reptile"
                      />
                      <CardContent style={{ height: "50px" }}>
                        <h2
                          style={{ marginTop: "0px" }}
                          variant="h5"
                          component="h2"
                        >
                          Design Thinking : The Beginner's Guide
                        </h2>
                      </CardContent>
                      <Box
                        display="flex"
                        flexDirection="row"
                        component="fieldset"
                        style={{ height: "12px" }}
                        borderColor="transparent"
                      >
                        <Rating name="read-only" value={3} readOnly />
                        <h3 style={{ margin: "5px 0px 0px 10px" }}>3.0</h3>
                      </Box>
                      <CardActions>
                        <h2 style={{ margin: "0px 0px 10px 10px" }}>
                          &#8377; 872
                        </h2>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
            </Grid>
          </div>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Navigate to="/login" />
        </Fragment>
      );
    }
  } else {
    return (
      <Fragment>
        <Navigate to="/login" />
      </Fragment>
    );
  }
}

export default Courses;
