import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import ButtonBase from "@mui/material/ButtonBase";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3283c9",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function Exams() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [selectedPage, setSelectedPage] = useState("EXAMS");
  const [examsPage, setExamsPage] = useState("UPCOMING");

  const [isLoading, setIsLoading] = useState(false);
  const [exams, setExams] = useState([]);

  const [selectedExam, setSelectedExam] = useState(0);

  function getData() {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["class_id"] = state.auth.user.class_id;
    body["division"] = state.auth.user.division;
    body["semester"] = state.auth.user.current_semester;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_exams", body, config)
      .then((res) => {
        setExams(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("___");
        console.log(err);
      });
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  function getContent() {
    if (isLoading) {
      return (
        <Fragment>
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              marginTop: "46vh",
            }}
          >
            <CircularProgress />
          </div>
        </Fragment>
      );
    } else {
      if (examsPage == "UPCOMING") {
        if (selectedPage == "EXAMS") {
          var examCount = 0;

          return (
            <div style={{ marginTop: "-30px" }}>
              <Grid container spacing={1.5}>
                {exams.map((exam, index) => (
                  <Fragment>
                    {moment(exam["end_date"], "DD-MM-YYYY").isAfter(
                      moment()
                    ) ? (
                      <Grid item md={3}>
                        <span style={{ display: "none" }}>
                          {(examCount += 1)}
                        </span>
                        <Card
                          style={{
                            minHeight: "100%",
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          onClick={(e) => {
                            setSelectedExam(index);
                            setSelectedPage("EXAM");
                          }}
                        >
                          <CardActionArea
                            sx={[
                              {
                                borderRadius: "6px",
                                border: "1.5px solid #d7ecff",
                                transition:
                                  "background-color 0.5s, border-color 0.5s",
                              },
                              (theme) => ({
                                "&:hover": {
                                  backgroundColor: "#ebf5ff",
                                  borderColor: "#3182bd",
                                },
                                ".MuiCardActionArea-focusHighlight": {
                                  background: "transparent",
                                },

                                "&& .MuiTouchRipple-child": {
                                  backgroundColor: "#084594",
                                },
                              }),
                            ]}
                            style={{ padding: "8px 7px 12px 13px" }}
                          >
                            <h2
                              style={{
                                marginTop: "0px",
                                color: "#084594",

                                fontFamily: "Lato-Bold",
                              }}
                            >
                              {exam["test"]}
                            </h2>

                            <p
                              style={{
                                margin: "-18px 0px 15px 1px",
                                fontSize: "14px",
                                fontFamily: "Lato-Regular",
                                color: "#3182bd",
                              }}
                            >
                              {exam["semester"]}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    width: "20%",
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                  }}
                                >
                                  Start:{" "}
                                </span>
                                <span
                                  style={{
                                    margin: "0px 0px 0px 5px",
                                    fontSize: "14px",
                                    fontFamily: "Lato-Regular",
                                    width: "80%",
                                    color: "#3182bd",
                                  }}
                                >
                                  {moment(
                                    exam["start_date"],
                                    "DD-MM-YYYY"
                                  ).format("Do MMMM, YYYY")}
                                </span>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      width: "20%",
                                      color: "#084594",

                                      fontFamily: "Lato-Bold",
                                    }}
                                  >
                                    End:{" "}
                                  </span>
                                  <span
                                    style={{
                                      margin: "0px 0px 0px 2.5px",
                                      fontSize: "14px",
                                      fontFamily: "Lato-Regular",
                                      width: "80%",
                                      color: "#3182bd",
                                    }}
                                  >
                                    {moment(
                                      exam["end_date"],
                                      "DD-MM-YYYY"
                                    ).format("Do MMMM, YYYY")}
                                  </span>
                                </div>
                              </div>

                              <ButtonBase
                                p={2}
                                value={index}
                                fullWidth
                                onClick={(e) => {
                                  setSelectedExam(index);
                                  setSelectedPage("EXAM");
                                }}
                                style={{
                                  backgroundColor: "#31a354",
                                  color: "white",
                                  height: "25px",
                                  width: "60px",
                                  borderRadius: "2px",
                                  margin: "8px 5px 0px 5px",
                                }}
                              >
                                <b>
                                  <p
                                    style={{
                                      marginTop: "15px",
                                      fontFamily: "Bahnschrift",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    VIEW
                                  </p>
                                </b>
                              </ButtonBase>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                ))}
                {examCount == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No upcoming Exams.</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          );
        } else if (selectedPage == "EXAM") {
          return (
            <div style={{ marginTop: "-15px" }}>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("EXAMS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 0px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "-10px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h2 style={{ fontSize: "20px" }}>
                      {exams[selectedExam]["test"] + " - "}
                      {exams[selectedExam]["semester"]}
                    </h2>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "Bahnschrift",
                        fontWeight: "lighter",
                        marginTop: "17px",
                      }}
                    >
                      {moment(
                        exams[selectedExam]["start_date"],
                        "DD-MM-YYYY"
                      ).format("Do MMMM, YYYY") + " "}
                      to
                      {" " +
                        moment(
                          exams[selectedExam]["end_date"],
                          "DD-MM-YYYY"
                        ).format("Do MMMM, YYYY")}
                    </div>
                  </div>
                </div>
              </div>
              <Grid container spacing={1.5}>
                {exams[selectedExam]["subjects"].map((subject, index) => (
                  <Grid item xs={4}>
                    <Card
                      style={{
                        minHeight: "100%",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "8px 12px 10px 12px",
                        border: "2px solid #d7ecff",
                        borderRadius: "6px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          fontFamily: "Lato-Bold",
                          marginBottom: "0px",
                          color: "#084594",
                        }}
                      >
                        {Object.keys(subject)[0]}
                      </div>
                      <span>
                        Total marks:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["marks_total"]}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Date:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {moment(
                            subject[Object.keys(subject)[0]]["date"],
                            "DD-MM-YYYY"
                          ).format("Do MMMM, YYYY")}
                        </b>
                      </span>{" "}
                      <br></br>
                      <span>
                        Time:{" "}
                        <b
                          style={{ color: "#084594", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["time"]}
                        </b>
                      </span>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          );
        }
      } else {
        var examCount = 0;
        if (selectedPage == "EXAMS") {
          return (
            <div style={{ marginTop: "-30px" }}>
              <Grid container spacing={1.5}>
                {exams.map((exam, index) => (
                  <Fragment>
                    {moment(exam["end_date"], "DD-MM-YYYY").isBefore(
                      moment()
                    ) ? (
                      <Grid item md={3}>
                        <span style={{ display: "none" }}>
                          {(examCount += 1)}
                        </span>
                        <Card
                          style={{
                            minHeight: "100%",
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          onClick={(e) => {
                            setSelectedExam(index);
                            setSelectedPage("EXAM");
                          }}
                        >
                          <CardActionArea
                            sx={[
                              {
                                borderRadius: "6px",
                                border: "1.5px solid #d7ecff",
                                transition:
                                  "background-color 0.5s, border-color 0.5s",
                              },
                              (theme) => ({
                                "&:hover": {
                                  backgroundColor: "#ebf5ff",
                                  borderColor: "#3182bd",
                                },
                                ".MuiCardActionArea-focusHighlight": {
                                  background: "transparent",
                                },

                                "&& .MuiTouchRipple-child": {
                                  backgroundColor: "#084594",
                                },
                              }),
                            ]}
                            style={{ padding: "8px 7px 12px 13px" }}
                          >
                            <h2
                              style={{
                                marginTop: "0px",
                                color: "#084594",

                                fontFamily: "Lato-Bold",
                              }}
                            >
                              {exam["test"]}
                            </h2>

                            <p
                              style={{
                                margin: "-18px 0px 15px 1px",
                                fontSize: "14px",
                                fontFamily: "Lato-Regular",
                                color: "#3182bd",
                              }}
                            >
                              {exam["semester"]}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    width: "20%",
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                  }}
                                >
                                  Start:{" "}
                                </span>
                                <span
                                  style={{
                                    margin: "0px 0px 0px 5px",
                                    fontSize: "14px",
                                    fontFamily: "Lato-Regular",
                                    width: "80%",
                                    color: "#3182bd",
                                  }}
                                >
                                  {moment(
                                    exam["start_date"],
                                    "DD-MM-YYYY"
                                  ).format("Do MMMM, YYYY")}
                                </span>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      width: "20%",
                                      color: "#084594",

                                      fontFamily: "Lato-Bold",
                                    }}
                                  >
                                    End:{" "}
                                  </span>
                                  <span
                                    style={{
                                      margin: "0px 0px 0px 2.5px",
                                      fontSize: "14px",
                                      fontFamily: "Lato-Regular",
                                      width: "80%",
                                      color: "#3182bd",
                                    }}
                                  >
                                    {moment(
                                      exam["end_date"],
                                      "DD-MM-YYYY"
                                    ).format("Do MMMM, YYYY")}
                                  </span>
                                </div>
                              </div>

                              <ButtonBase
                                p={2}
                                value={index}
                                fullWidth
                                onClick={(e) => {
                                  setSelectedExam(index);
                                  setSelectedPage("EXAM");
                                }}
                                style={{
                                  backgroundColor: "#31a354",
                                  color: "white",
                                  height: "25px",
                                  width: "60px",
                                  borderRadius: "2px",
                                  margin: "8px 5px 0px 5px",
                                }}
                              >
                                <b>
                                  <p
                                    style={{
                                      marginTop: "15px",
                                      fontFamily: "Bahnschrift",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    VIEW
                                  </p>
                                </b>
                              </ButtonBase>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                ))}
                {examCount == 0 ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No previous exams.</h4>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          );
        } else if (selectedPage == "EXAM") {
          return (
            <div style={{ marginTop: "-15px" }}>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("EXAMS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 0px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "-10px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <h2 style={{ fontSize: "20px" }}>
                      {exams[selectedExam]["test"] + " - "}
                      {exams[selectedExam]["semester"]}
                    </h2>
                    <div
                      style={{
                        fontSize: "20px",
                        fontWeight: "bold",
                        fontFamily: "Bahnschrift",
                        fontWeight: "lighter",
                        marginTop: "17px",
                      }}
                    >
                      {moment(
                        exams[selectedExam]["start_date"],
                        "DD-MM-YYYY"
                      ).format("Do MMMM, YYYY") + " "}
                      to
                      {" " +
                        moment(
                          exams[selectedExam]["end_date"],
                          "DD-MM-YYYY"
                        ).format("Do MMMM, YYYY")}
                    </div>
                  </div>
                </div>
              </div>
              <Grid container spacing={1.5}>
                {exams[selectedExam]["subjects"].map((subject, index) => (
                  <Grid item xs={4}>
                    <Card
                      style={{
                        minHeight: "100%",
                        backgroundColor: "white",
                        boxShadow: "none",
                        border: "2px solid #d7ecff",
                        borderRadius: "6px",
                        padding: "8px 12px 10px 12px",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                          fontFamily: "Lato-Bold",
                          marginBottom: "0px",
                          color: "#084594",
                        }}
                      >
                        {Object.keys(subject)[0]}
                      </div>
                      <span>
                        Total marks:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["marks_total"]}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Date:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {moment(
                            subject[Object.keys(subject)[0]]["date"],
                            "DD-MM-YYYY"
                          ).format("Do MMMM, YYYY")}
                        </b>
                      </span>
                      <br></br>
                      <span>
                        Time:{" "}
                        <b
                          style={{ color: "#3182bd", fontFamily: "Lato-Bold" }}
                        >
                          {subject[Object.keys(subject)[0]]["time"]}
                        </b>
                      </span>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </div>
          );
        }
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_student) {
        if (state.auth.expiry >= new Date()) {
          if (isLoading) {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginTop: "46vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Sidebar
                  tabs={
                    <Grid container spacing={1.5} justifyContent={"flex-end"}>
                      <Grid item md={4}>
                        {examsPage == "UPCOMING" ? (
                          <Button
                            fullWidth
                            value={"UPCOMING"}
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              UPCOMING EXAMS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value={"UPCOMING"}
                            onClick={() => {
                              setExamsPage("UPCOMING");
                              setSelectedPage("EXAMS");
                            }}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              UPCOMING EXAMS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {examsPage == "PREVIOUS" ? (
                          <Button
                            fullWidth
                            value="PREVIOUS"
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              PREVIOUS EXAMS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="PREVIOUS"
                            onClick={() => {
                              setExamsPage("PREVIOUS");
                              setSelectedPage("EXAMS");
                            }}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              PREVIOUS EXAMS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  }
                />
                <div
                  style={{
                    margin: "82px 15px 20px 85px",
                    backgroundColor: "white",
                  }}
                >
                  {getContent()}
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Student");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Exams;
